.navlinkParent {
  + * {
    width: 100%;
  }

  button {
    svg {
      stroke: var(--mantine-color-gray-6) !important;
    }
  }

  &[data-active] {
    button {
      svg {
        stroke: black !important;
      }
    }
  }
}

.navlinkDivider {
  margin: 0.25rem 0;
}

.navlinkChild {
  width: 100%;

  &[data-active] {
    background-color: var(--mantine-color-gray-2) !important;

    @mixin dark {
      color: var(--mantine-color-default-color) !important;
      background-color: var(--mantine-color-dark-9) !important;
    }
  }
}
