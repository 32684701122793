.root {
  &[data-variant*="hint"] {
    background-color: var(--mantine-color-violet-0);
    @mixin dark {
      background-color: var(--mantine-color-violet-9);
    }
  }

  &[data-variant*="collapsing"] {
    @mixin smaller-than $mantine-breakpoint-sm {
      border-radius: 0 !important;
      border-left: none !important;
      border-right: none !important;
      margin-top: -1px;
    }

    &:has(+ &[data-variant*="grouped"]) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & + &[data-variant*="grouped"] {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      margin-top: -1px;
    }
  }
}
