.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 4px;
  border-radius: var(--mantine-radius-sm);
  border: 1px solid var(--mantine-color-gray-3);
  background-color: var(--mantine-color-white);

  @mixin dark {
    border: 1px solid var(--mantine-color-gray-7);
    background-color: var(--mantine-color-dark-7);
  }

  &:focus-within {
    border-color: var(--mantine-color-lime-6);
  }
}

.control {
  color: var(--mantine-color-gray-8);
  background-color: var(--mantine-color-white);
  border: 1px solid var(--mantine-color-gray-3);

  @mixin dark {
    color: var(--mantine-color-gray-2);
    background-color: var(--mantine-color-dark-7);
    border: 1px solid var(--mantine-color-gray-7);
  }

  &:disabled {
    opacity: 0.8;
    color: var(--mantine-color-gray-5);
    background-color: transparent;
    background-color: 1px solid var(--mantine-color-gray-3);

    @mixin dark {
      background-color: 1px solid var(--mantine-color-gray-7);
    }
  }
}

.input {
  flex: 1;
  margin: 0;
  text-align: center;
  color: 1px solid var(--mantine-color-black);

  @mixin dark {
    color: 1px solid var(--mantine-color-white);
  }
}

.error {
  color: var(--mantine-color-red-5);
  border-color: var(--mantine-color-red-3);

  @mixin dark {
    border-color: var(--mantine-color-red-7);
  }

  &:focus-within {
    border-color: var(--mantine-color-red-3);

    @mixin dark {
      border-color: var(--mantine-color-red-7);
    }
  }
}
