.wrapper {
  margin-bottom: 5px;
  --mantine-color-error: var(--mantine-color-red-text);
  --input-asterisk-color: var(--mantine-color-red-text);
}

.description,
.error {
  line-height: var(--mantine-line-height);
  --mantine-color-error: var(--mantine-color-red-text);
}

.required {
  color: var(--mantine-color-red-text);
}
