.root {
  --subtle-border: rgba(64, 0, 170, 10%);
  --raised-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px
    7px 7px -5px rgba(0, 0, 0, 0.04);
  --super-gradient: linear-gradient(
    243deg,
    #dbc6ff 0%,
    #ddc8ff 18.1%,
    #cadcff 50.18%,
    #c1ff90 80.83%,
    #c3ff95 99.32%
  );

  font-weight: 600 !important;

  &[data-size="md"] {
    font-size: var(--mantine-font-size-sm);

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  /* Update ActionIcon sizes to match the heights of buttons (of two sizes smaller) */
  &[data-size="lg"] {
    --ai-size: calc(2.25rem * var(--mantine-scale)) !important;
  }
  &[data-size="xl"] {
    --ai-size: calc(2.625rem * var(--mantine-scale)) !important;
  }

  &[data-variant*="attention"],
  &[data-variant*="super"],
  &[data-variant*="warning"] {
    color: var(--mantine-color-black);
    border: 1px solid var(--subtle-border);

    @mixin dark {
      border-color: transparent;
    }

    &[data-variant*="raised"] {
      box-shadow: var(--raised-shadow);
    }
  }

  &[data-variant*="outline"] {
    @mixin light {
      border: 1px solid var(--subtle-border);
    }

    @mixin dark {
      border: 1px solid var(--mantine-color-default-border);
    }
  }

  &[data-variant*="attention"] {
    background: var(--mantine-color-kiwi-2);
  }

  &[data-variant*="super"] {
    background: var(--super-gradient);
    background-origin: border-box;
  }

  &[data-variant*="warning"] {
    background: var(--mantine-color-red-2);
  }

  &[data-variant*="default raised"] {
    box-shadow: var(--raised-shadow);

    @mixin light {
      border: 1px solid var(--subtle-border);
    }

    @mixin dark {
      background-color: var(--mantine-color-default);
      border: 1px solid var(--mantine-color-default-border);
    }
  }

  &[data-variant*="light raised"] {
    box-shadow: var(--raised-shadow);
    color: var(--mantine-color-black);
    background-color: var(--mantine-color-white);
    border: 1px solid var(--subtle-border);

    @mixin dark {
      color: var(--mantine-color-white);
      background-color: var(--mantine-color-default);
      border: 1px solid var(--mantine-color-default-border);
    }
  }
}
