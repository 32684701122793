.root {
  font-weight: 600;
  border-radius: var(--mantine-radius-default);
  color: var(--mantine-color-gray-6);

  &[data-variant="light"] {
    &[data-active] {
      background-color: var(--mantine-color-kiwi-2);

      @mixin light {
        color: var(--mantine-color-default-color);
      }

      @mixin dark {
        color: var(--mantine-color-default);
      }
    }
  }
}
