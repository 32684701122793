.container {
  position: fixed;
  width: 100%;
  bottom: 0;

  color: var(--mantine-color-dark-8);
  background-color: var(--mantine-color-gray-0);

  border-left: 0;
  border-right: 0;
  border-bottom: 0;

  @mixin dark {
    color: var(--mantine-color-gray-0);
    background-color: var(--mantine-color-dark-6);
  }
}
