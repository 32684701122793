.root {
  @mixin light {
    background-color: var(--mantine-color-gray-0);
  }

  @mixin dark {
    background-color: var(--mantine-color-dark-9);
  }

  .navbar {
    @mixin dark {
      background-color: var(--mantine-color-dark-7);
    }
  }
}
