.root {
  --border: rgba(64, 0, 170, 10%);
  --shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 7px 7px
    -5px rgba(0, 0, 0, 0.04);

  overflow: visible;

  @mixin light {
    background-color: var(--mantine-color-gray-0);
  }

  @mixin dark {
    background-color: var(--mantine-color-dark-9);
  }

  .control:before {
    display: none;
  }

  .indicator {
    border: 1px solid var(--border);
    box-shadow: var(--shadow);
    border-radius: var(--mantine-radius-default);
  }

  .label {
    padding: calc(0.5rem * var(--mantine-scale)) calc(1rem * var(--mantine-scale));
    font-weight: 600;

    > div {
      gap: calc(0.375rem * var(--mantine-scale));

      > div {
        margin-left: 0 !important;
      }
    }
  }
}
