.root {
  font-weight: 600;

  &[data-variant="light"] {
    color: var(--mantine-color-black);
  }

  &[data-size="md"] {
    height: 1.375rem;
    font-size: var(--mantine-font-size-sm);
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    color: var(--mantine-color-black);
  }
}
