.option {
  &[data-checked] {
    background-color: var(--mantine-color-gray-2) !important;

    @mixin dark {
      color: var(--mantine-color-default-color) !important;
      background-color: var(--mantine-color-dark-9) !important;
    }
  }
}

.input {
  padding-inline-start: calc(var(--input-padding-inline-start) + var(--mantine-spacing-xs));

  font-weight: 600;
}

.section {
  margin: 6px;
  color: black;

  font-weight: 600;
  font-size: var(--input-fz, var(--input-fz, var(--mantine-font-size-sm)));

  @mixin dark {
    color: white;
  }
}
